
.testicontainer{
    margin-top: 50px;
    width: 1250px;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: -2px;

}
.testiframe{
    width: 1250px;
    height: 500px;
    background-color: rgb(238, 247, 250);
    margin-left: 10px;
    border-radius: 20px;
    margin-top: 0px;
    /* margin-bottom: 20px; */
    align-items: center;
    justify-content: center;
    text-align: center;
}

.frame{
    width: 1150px;
    height: auto;
    margin-top: 20px;
    margin-left: 50px;
}
.name{
    width: 1150px;
    margin: auto;
    background-color:rgb(138, 216, 241);
    height: auto;
    margin-top: 25px;
    border-radius: 20px;
}
.frame1{
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    border-radius: 12px;
}
.picture{
    display: flex;
    height: 80px;
    width: 80px;
    border-radius: 80px;
    margin-top: 100px;
}
.frame2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1px;
    padding: 26px;
    background-color: white;
    margin: 15px;
    border-radius: 20px;
}  

.text{
    display: flex;
    color: black;
    text-align: center;
    justify-content: center;
    font-size: 22px;
    /* font-weight: 600; */
    margin-top: 40px;
}
.text1{
    display: flex;
    flex-direction: column;
    text-align: justify;
    word-spacing: -3px;
    justify-content: left;
    font-size: 16px;
    /* font-weight: 600; */
    color: black;    
}
/* .button{
    background-color: rgb(1, 1, 77);
    color: white;
    font-size: 18px;
    padding: 10px;
    border-radius: 18px;
} */
.slick-slide>div{
    margin: 20px;
}
