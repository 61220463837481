.aboutcontainer{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutdigital {
    margin-top: 50px;
    width: 500px;
    height: auto;
    margin-left: 50px;
     
}

.aboutdigital h1{
    font-size: 20px;
    margin-left: 50px;

}

.aboutdigital p{
    width: 500px;
    text-align: justify;
    font-size: 16px;
    line-height: 2rem;
    margin-left: 50px;

    
}

.aboutdigipic img{
   margin-left: 130px;
   margin-top: 200px;
   width: 500px;
   height: auto; 
}

@media screen and (max-width: 768px) {
    .aboutcontainer{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        margin-bottom: 120px;
    }

    /* .aboutdigital {
        width: 300px;
        margin-left: 10px;
    } */

    .aboutdigital h1{
        font-size: 18px;
        margin-left: -50px;
    }
    
    .aboutdigital p{
        width: 600px;
        text-align: justify;
        font-size: 16px;
        line-height: 2rem;
        margin-left: -50px;
        
    }
    .aboutdigipic img{
        margin-top: -10px;
       width: 600px;
       height: auto; 
       margin-left: 10px;
    }
}

@media screen and (max-width: 480px) {

    .aboutcontainer{
        width: auto;
        height: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    
    }

    /* .aboutdigital {
        width: 100%;
        height: auto;
        padding: 30px;
    } */

    .aboutdigital h1{
        font-size: 12px;
        margin-left: 50px;
        width: 350px;
    }
    
    .aboutdigital p{
        width: 350px;
        text-align: justify;
        font-size: 12px;
        line-height: 2rem;
        margin-left: 50px;
        
    }
    .aboutdigipic img{
        margin-top: -10px;
       width: 300px;
       height: auto; 
       margin-left: 20px;
    }

}
