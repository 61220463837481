.topiccontainer{
    /* border: 1250px 1670px; */
    width: 100%;
    height: 100%;
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card{
    height: 400px;
    width: 350px;
    border: 1px solid lightgray;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    margin: 20px;
    border-radius: 20px;
    padding: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.topicardcontainer{
    width: 1250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.innerborder1{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0;    
   
}
.discover{
    margin-top: -80px;
    margin-left: 100px;
}

.victorimg{
    display: flex;
}

.topicardcontainer .card img{
    width: 100px;
    height: 100px; 
    /* border-radius: 50%;
    border: 1px solid lightblue; */
    box-shadow: none  ;
}

.innerborder1 text{
   margin-top: -70px;  
   height: 20px;
   width: 300px;
   display: flex;
   margin-left: 100px;
   font-size: 20px;
   font-weight: 600;
   
}
.innerborder1 .foryour1 h1{
    /* margin-left: 100px;
    margin-top: -60px;
    text-wrap: wrap;
    width: 40%;
    height: 80px;
    align-items: center;
    align-content: center; */
    text-align: center;
    font-size: 24px;
}

.card h2{
    color: darkblue;
}
.card p{
    word-spacing: normal;
    /* text-align:justify; */
    align-items: center;
    padding: 20px 10px;
    line-height: 25px;
    font-size: 14px;
}
/* .topicardcontainer .card a{
    color: darkgray;
    margin-left: 15%;
    text-decoration: underline;
    text-align: center;
    align-items: center;
    justify-items: center;
    justify-content: center;   
} */
.card .txt{
    font-size: 14px;
    padding: 20px 10px;

}
@media screen and (max-width:360px) {
    
    .topiccontainer{
        position: relative;
        max-width: 1250px;
        max-height: 100%;
      }
    .card{
          position: relative;
          max-width: 100%;
          max-height: 100%;
      }
    
}


