.clintimagecontainer{
    margin-top: 30px;
    /* width: 1250px; */
    width: 100%;
    height: 200px;
    justify-content: center;
    align-content: center;
    background-position: center;
    margin-left: 0px; 
    border-radius: 20px;  
}

.ourclientsheadding h1{
    font-family : sans-serif;
    text-align: center;
    margin-bottom: 5px;
    font-size: 24px;
}

.clintimage img{
    width: 250px;
    height: 150px;
    border-radius: 10px;  
    padding: 2px;

}

@media screen and (max-width: 768px) {
    .clintimagecontainer {
      width: auto;
    }
    .clintimage img {
      width: 175px;
      height: auto;
    }
  }

/* Media query for screens up to 480px (phones and smaller screens) */
@media screen and (max-width: 480px) {

  .clintimagecontainer {
  width: auto;
  height: auto;
  margin-bottom: -100px;
  }
.clintimage img {
  width: 100px;
  height: auto;
  }

}