.container{
    width: 100%;
    height: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.card{
    height: 400px;
    width: 350px;
    border: 1px solid lightgray;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    margin: 20px;
    border-radius: 20px;
    padding: 20px;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.cardcontainer{
    width: 100%;
    max-width: 1250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.innerborder{
    left: 0;
    border: 1250px;
    margin-left: 0px;
}
.victorimg{
    display: flex;
}

.cardcontainer .card img{
    width: 100px;
    height: 100px; 
    /* border-radius: 50%;
    border: 1px solid lightskyblue; */
    box-shadow: none  ;
  
}

.innerborder text{
   margin-top: -70px;  
   height: 20px;
   width: 300px;
   display: flex;
   margin-left: 100px;
   font-size: 20px;
   font-weight: 600;
   
}
.foryour1 h1{
    /* margin-left: 100px;
    margin-top: -30px;
    text-wrap: wrap;
    width: 40%;
    height: 80px;
    align-items: center;
    align-content: center;
    display: flex; */
    text-align: center;
    font-size: 24px;
}

.card h2{
    color: darkblue;
    font-size: 18px;
}
.card p{
    word-spacing: normal;
    /* text-align:justify; */
    align-items: center;
    padding: 20px 10px;
    font-size: 14px;
}



