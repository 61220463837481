.header1{
    /* width: 1250px; */
    width: 100%;
    height: auto;
    max-height: 100%;
    margin-left: -5px;
    background-image: url('../../Assets/capaback3.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    margin-top: 100px;
}
.capaimg{
    display: flex;
    flex-direction: column;
}
.text-container{
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
    margin-left: 80px;
    font-size: 20px;
    line-height: 1.5;
}
.capaimg img{
    width: 50px;
    height: 50px;
    border: 5px solid white;
    border-color: white;
    border-radius: 50%;
    margin: 10px 12px 50px 10px;
    cursor: pointer;
       
}
.satiimgtxt{
    display: flex;
    color: white;   
    margin-left: 60px;
}
.subtext h1{
    display: flex;
    flex-direction: column;    
    margin: 30px 20px 82px 30px;
    cursor: pointer;
    font-size: 18px;
}
.text-container h1{
    margin-top: 50px;
    font-size: 28px;
}
.text-container p{
   font-size: 16px; 
   width: 1100px;
   max-width: 100%;
}

/* Media query for screens up to 480px (phones and smaller screens) */
@media screen and (max-width: 480px) {

    .capaimg img{
        margin-top: 25px;
        width: 25px;
        height: auto;
    }

    .text-container h1{  
        font-size: 16px;
    }

    .subtext h1{
        font-size: 12px;
    }

    .text-container p{
        font-size: 10px;
        width: auto;
    }
}
