:root {

    --dark-blue: #142257;
    --gray-color: #9EA3B5;
    --light-pink: #EFC0A6;
}

    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }
html, body {
    /* margin: 0;
    padding: 0; */
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    /* font-weight: 200; */
    /* font-size: 12px; */
   
}

.wrapper {
    width: auto;
    max-width: 100vh;
    margin: 0 auto;
}
