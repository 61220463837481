.contact-container{
    width: 100%;
    height: 100%;
}

.contpic{
    width: 100%;
    height: 100%;
    max-height: 500px;
}
.contact-container h1{
    margin-top: -150px;
    margin-left: 200px;
    font-size: 42px;
    color: white;
}
@media screen and (max-width: 768px) {
    .contact-container{
        width: auto;
        height: auto;
    }
    
    .contpic {
        width: 100%;
        height: auto; 
    }

    .contact-container h1 {
        display: flex;
        margin-top: -200px;
        color: white;
        font-size: 32px;
    }
    
}

@media screen and (max-width: 480px) {
    .contact-container{
        width: auto;
        height: auto;
    }
    
    .contpic {
        width: 100%;
        height: auto; 
    }

    .contact-container h1 {
        display: flex;
        margin-top: -100px;
        margin-left: 120px;
        color: white;
        font-size: 20px;
    }
    
}