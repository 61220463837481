.stafheader{
    width: 100%;
    height: 100%;
}
.stafheader img{
    width: 100%;
    height: 400px;
    max-height: 100%;
    margin-left: -5px;
    
}

.stafheader h1{
    margin-top: -250px;
    margin-left: 100px;
    font-size: 24px;
    padding: 10px;
    color: white;
}

.stafheader p{
    margin-left: 100px;
    width: 800px;
    font-size: 20px;
    padding: 10px;
    color: white;
    text-align: justify;
}

@media screen and (max-width:768px) {

    .stafheader{
        width: auto;
        height: auto;
    }
    .stafheader img{
        width: 100%;
        height: 280px;
    }
    .stafheader h1{
        margin-top: -200px;
        margin-left: 20px;
        font-size: 16px;
    }
    .stafheader p{
        margin-left: 10px;
        width: auto;
        font-size: 14px;
        padding: 20px;
    }
    
}

@media screen and (max-width:480px) {

    .stafheader{
        width: auto;
        height: auto;
    }
    .stafheader img{
        width: 100%;
        height: 160px;
    }
    .stafheader h1{
        margin-top: -130px;
        margin-left: 10px;
        font-size: 12px;
    }
    .stafheader p{
        margin-left: 10px;
        margin-top: -20px;
        width: auto;
        font-size: 10px;

    }
    
}

