.saasheader{
    width: 100%;
    height: 100%;
}
.saasheader img{
    width: 100%;
    height: 400px;
    margin-left: -5px;
    
}

.saasheader h1{
    margin-top: -220px;
    margin-left: 100px;
    font-size: 24px;
    padding: 10px;
    color: white;
}

.saasheader p{
    margin-left: 100px;
    width: 600px;
    font-size: 20px;
    padding: 10px;
    color: white;
    text-align: justify;
}

@media screen and (max-width:768px) {

    .saasheader{
        width: auto;
        height: auto;
    }
    .saasheader img{
        width: 100%;
        height: auto;        
    }
    
    .saasheader h1{
        margin-top: -250px;
        font-size: 20px;
        padding: 10px;
        margin-left: 10px;
    }
    
    .saasheader p{
        width: 400px;
        font-size: 16px;
        padding: 10px;
        margin-left: 10px;
    }
}

@media screen and (max-width:480px) {

    .saasheader{
        width: auto;
        height: auto;
    }
    .saasheader img{
        width: 100%;
        height: auto;        
    }
    
    .saasheader h1{
        margin-top: -150px;
        font-size: 16px;
        margin-left: 10px;
    }
    
    .saasheader p{
        margin-top: -20px;
        width: 280px;
        font-size: 12px;
        padding: 10px;
        margin-left: 10px;
    }

}