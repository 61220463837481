.ent-container{
    width: 100%;
    height: auto;
    display: flex;
}

.about-ent {
    margin-top: 30px;
    width: 500px;
    height: auto;
    margin-left: 40px;
}

.about-ent h1{
    font-size: 20px;
}

.about-ent p{
    width: 500px;
    text-align: justify;
    font-size: 16px;
    line-height: 2rem;
    
}

.aboutentpic img{
   margin-left: 200px;
   margin-top: 200px;
   width: 500px;
   height: auto; 
}