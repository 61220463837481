body{
    overflow-x: hidden;
  }
.infocontainer {
    width: 100%; /* Adjusted for responsiveness */
    height: 100%;
    display: flex;
    flex-wrap: wrap; /* Allow flex items to wrap */
    justify-content: center; /* Center align flex items horizontally */
    align-items: center; /* Center align flex items vertically */
    padding: 20px; /* Added padding for spacing */
    box-sizing: border-box; /* Ensure padding is included in width/height */
}

.about {
    width: 100%;
    max-width: 400px; 
    height: auto; /* Let content dictate height */
    text-align: justify;
    padding: 20px;
    margin: 20px;
    font-size: 18px;
    box-sizing: border-box;
}

.about p{
    width: 100%;
    max-width: 400px;
}

.aboutimg {
    width: 400px;
    height: 450px;
    max-width: 100%; /* Ensure it doesn't exceed parent's width */
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.timg1{
    width: 150px;
    height: 120px;
    margin: 10px;
    border-radius: 30px;
    border: 5px solid darkblue;
   
}
.timg2{
    width: 150px;
    height: 120px;
    margin: 10px;
    border-radius: 30px;
    border: 5px solid darkblue;
}
.timg3{
    width: 150px;
    height: 120px;
    margin: 10px;
    border-radius: 30px;
    border: 5px solid darkblue;
}
.timg4{
    width: 150px;
    height: 120px;
    margin: 10px;
    border-radius: 30px;
    border: 5px solid darkblue;
}
.timg5{
    width: 300px;
    height: 120px;
    margin: 10px;
    border-radius: 30px;
    border: 5px solid darkblue;
}

.about img {
    width: 100%;
    height: auto;
    border-radius: 30px;
    border: 5px solid darkblue;
}

.about button {
    background-color: darkblue;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    margin-top: 10px;
}

.about p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
}

.about h2 {
    font-size: 20px;
    font-weight: 600;
    color: darkblue;
    margin-bottom: 15px;
}

@media screen and (max-width: 768px) and (min-width: 360px) {
    .infocontainer {
        /* Adjust container for smaller screens if necessary */
    .about{
        width: auto;
        margin-top: 50px;
    }
    .aboutimg{
        max-width: 100%;
        height: auto;
    }

    h2{
        font-size: 14px;
    }
    
    }  
}

