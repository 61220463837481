.amcontainer{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutam {
    margin-top: 30px;
    width: 500px;
    height: auto;
    margin-left: 40px;
}

.aboutam h1{
    font-size: 20px;
}

.aboutam p{
    width: 500px;
    text-align: justify;
    font-size: 16px;
    line-height: 2rem;
    
}

.aboutampic img{
   margin-left: 100px;
   margin-top: 100px;
   width: 350px;
   height: auto; 
}

@media screen and (max-width: 768px) {
    .amcontainer{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 120px;
    }
    
    .aboutam h1{
        font-size: 18px;
        margin-left: 0px;
    }
    
    .aboutam p{
        width: 500px;
        text-align: justify;
        font-size: 16px;
        line-height: 2rem;
        margin-left: 0px;
    }
    
    .aboutampic img{
       margin-top: -10px;
       width: 400px;
       height: auto; 
       margin-left: 10px;
    }
}

@media screen and (max-width: 480px) {
    .amcontainer{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-bottom:  10px;
    }
    
    .aboutam h1{
        font-size: 14px;
        margin-left: 50px;
    }
    
    .aboutam p{
        width: 350px;
        text-align: justify;
        font-size: 12px;
        line-height: 2rem;
        margin-left: 50px;
    }
    
    .aboutampic img{
       margin-top: -10px;
       width: 300px;
       height: auto; 
       margin-left: 10px;
    }
}