.swiper {
  width: 100%;
  height: 80%;
  overflow: hidden;
  overflow-x: hidden;

}

.swiper-slide {
  text-align: center;
  font-size: 1.125rem;
  background: #fff;
  justify-content: center;
  text-align: left;

}
.content{
  text-align: left;
  margin-left: 20px;
  padding: 20px;
  margin-top: -250px;
  color: white;

}
p{
  width: 70vw;
  font-size: 1.125rem;
}

.swiper-slide img {
  display: block;
  overflow-x: hidden;
  width: 100%;
  height: 80vh;
  object-fit: cover;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}


@media screen and (max-width: 768px) {
  .swiper {
    position: relative;
    width: auto;
    height: auto;
  }
  
  .swiper-slide img {
    width: 100%;
    height: 400px;
  }
  h1{
    font-size: 14px;
  }
  p{
    font-size: 12px;
  }
}

/* Media query for screens up to 480px (phones and smaller screens) */
@media screen and (max-width: 480px) {

.swiper {
  position: relative;
  width: auto;
  height: auto;
}

.swiper-slide img {
  width: 100%;
  height: 400px;
}
h1{
  font-size: 12px;
}
p{
  font-size: 10px;
}


}
