.cs-container1{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.cs-container1 .aboutcs {
    margin-top: 30px;
    width: 500px;
    height: auto;
}

.aboutcs h1{
    font-size: 20px;
}

.aboutcs p{
    width: 500px;
    text-align: justify;
    font-size: 16px;
    line-height: 2rem;
    
}

.cs-container1 .about-cspic img{
   margin-left: 100px;
   margin-top: 100px;
   width: 500px;
   height: auto; 

}

@media screen and (max-width: 768px) {
    .cs-container1{
        width: auto;
        height: auto;
        /* margin-top: -500px; */
        margin-bottom: 120px;
        display: flex;
        flex-direction: column;
    }

    .aboutcs {
        width: 300px;
    }

    .aboutcs h1{
        font-size: 14px;
    }
    
    .aboutcs p{
        width: 400px;
        font-size: 12px;
        
    }
    .cs-container1 .about-cspic img{
        width: 500px;
        height: auto; 
        margin-left: -50px;
        margin-top: -40px;
    }
}

@media screen and (max-width: 480px) {

    .cs-container1{
        width: auto;
        height: 100%;
        margin-top: 20px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        text-align: center;
    
    }

    .cs-container1 .aboutcs {
        width: auto;
       
    }

    .cs-container1 .aboutcs h1{
        width: 350px;
        font-size: 12px;
        margin-left: -25px;
       
    }
    
    .cs-container1 .aboutcs p{
        width: 320px;
        padding: 10px;
        height: auto;
        font-size: 10px;
        margin-left: 0px;
        /* text-align: center;
        align-content: center; */
        
    }
    .cs-container1 .about-cspic img{
        margin-left: 0px;
        margin-top: -10px;
        width: 400px;
        height: auto; 
    }

}