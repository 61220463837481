.carousel-container {
    width: 100%;
    margin: 0 auto;
    padding: 50px;
    margin-top: 100px;
    margin-bottom: 50px;    
  }
  
  .imgcontainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f2f2f2; 
    border-radius: 20px; 
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .carouselimg{
    border-radius: 10px;
    overflow-x: hidden;
    border: 1px solid red;
  }

    
  .herotitle {
    width: 100%;
    max-width: 400px;
    font-size: 24px;
    margin-top: -180px;
    margin-bottom: 10px;
    color: white;
    text-decoration: none;
  }
  
  .herotext {
    width: 100%;
    max-width: 400px;
    font-size: 16px;
    text-decoration: none;
    color: white;
    line-height: 1.6; 
  }
  
  .carouselimg:hover {
    cursor: pointer;
  }
  
  .carousel-navigation {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
  
  .carousel-navigation button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .carousel-navigation button:hover {
    background-color: #0056b3; 
  }


@media (max-width: 768px) {

  .carousel-container {
    width: auto;
    margin: 0 auto;
    padding: 10px;
    
  }

  .carouselimg{
    width: auto;
    height: 160px;
  }

  .herotitle {
    font-size: 12px;
    margin-top: -130px;
  }

  .herotext {
    font-size: 10px;
  }
}

@media (max-width: 480px) {

  .carousel-container {
    width: auto;
    height: 20vh;
    margin: 0 auto;
    padding: 10px;
    margin-top: 20px;
    
  }

  .carouselimg{
    width: auto;
    height: 160px;
  }

  .herotitle {
    font-size: 12px;
    margin-top: -130px;
  }

  .herotext {
    font-size: 10px;
  }
}