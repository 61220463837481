 .mainfooter-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 0; /* Added padding for spacing */
  }
  
  .mainfooterframe {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%; /* Added max-width for better control */
    background-color: rgb(2, 2, 45);
    color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 20px;
  }
  
  .techadd img {
    width: 100%;
    max-width: 180px;
    height: auto;
    border-radius: 20px;
  }
  
  .contactus1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .contactus1 p {
    font-size: 16px;
    font-weight: 600;
  }
  
  .contactus1 button {
    padding: 10px 20px;
    border: none;
    background-color: rgb(12, 12, 173);
    color: white;
    font-size: 16px;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .contactinfo {
    width: 100%;
    display: flex;
    flex-wrap: wrap; /* Allow elements to wrap */
    justify-content: center;
    text-align: center;
  }
  
  .maintechlink {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 300px; /* Adjust width as needed */
    margin-top: 30px;
    text-align: left; /* Align links to the left */
  }

  .maintech {
    width: 100%;
    max-width: 300px;
    margin-top: 40px;
    padding: 20px;
    text-align: left;
  }
  
  .maintech p {
    font-size: 14px;
    color: white;
  }
  
  .maintech1 {
    width: 100%;
    max-width: 300px;
    margin-top: 40px;
    padding: 20px;
    text-align: left;
  }
  
  .maintech1 p {
    font-size: 14px;
    color: white;
  }
  .techadd{
    margin-top: -30px;
  }
  
  .maintechlink {
    width: 100%;
    max-width: 200px;
    margin-top: 60px;
  }
  
  .maintechlink p {
    font-size: 14px;
    color: white;
  }
  
  .maintechlink h1 {
    font-size: 18px;
    color: white;
  }
  .maintechservices{
    width: 100%;
    max-width: 300px;
    margin-top: 30px;
  }
  
  .maintech h1,
  .maintech1 h1 {
    font-size: 18px;
    color: white;
  }
  
  .icon-link {
    font-size: 32px;
    color: white;
    padding: 10px;
  }
  
  .Socialmedia .icon:hover {
    cursor: pointer;
    background-color: aqua;
    transition: background-color 0.3s ease;
  }
  
  .thanks {
    margin-top: 20px;
  }
  
  .thanks p {
    font-size: 14px;
  }
  
  .privacy:hover {
    cursor: pointer;
  }
  
  .mainfooterframe .contactus1 button:hover {
    transform: scale(1.05);
  }
  
  /* Media Queries */
  
  @media (max-width: 768px) {
    .mainfooterframe {
      width: auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
    }

    .maintech,
    .maintechservices,
    .maintech1 {
      margin-left: 0;
      margin-right: 0;
      text-align: center;
    }
  
    .maintech p,
    .maintech1 p,
    .maintechlink p {
      width: auto;
      margin-left: 0;
      font-size: 12px;
    }
  
    .maintechlink {
      margin-top: 60px;
    }
  }
  
  @media (max-width: 480px) {
    .mainfooterframe {
      padding: 10px;
    }
  
    .contactinfo {
      flex-direction: column;
      align-items: center;
    }
  
    .maintech,
    .maintech1,
    .maintechlink {
      width: 100%;
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      text-align: center;
    }
  
    .maintech p,
    .maintech1 p,
    .maintechlink p {
      margin-left: 0;
      font-size: 10px;
    }
  }
   