   .ReadMore {
    position: relative;
    /* font-family: poplin, sans-serif; */
     /* Example font family */
  }
  
  .ReadMore .textBox {
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: 20px;
    padding: 20px;
    color: white;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  }
  
  .ReadMore p{
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .ReadMore button {
    background-color: #007bff; /* Blue */
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .ReadMore button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }