.staffing-topiccontainer1{
    width: 100%;
    height: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.staffing-topic{
    width: 100%;
    height: 100%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.staffing-card{
    height: 400px;
    width: 350px;
    border: 1px solid lightgray;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    margin: 20px;
    border-radius: 20px;
    padding: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.staffing-cardcontainer{
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-left: 200px; */
}
/* .staffing-innerborder{
    left: 0;
    margin-left: 0;
} */
.staffing-vectorimg{
    display: flex;
}

.staffing-cardcontainer .staffing-card img{
    width: 100px;
    height: 100px; 
    /* border-radius: 50%;
    border: 1px solid lightblue; */
    box-shadow: none  ;
}

text{
   margin-top: -70px;  
   height: 20px;
   width: 300px;
   display: flex;
   margin-left: 150px;
   font-size: 14px;
   font-weight: 300;
   
}
.staffing-foryour h1{
    /* margin-left: 100px;
    margin-top: -30px;
    text-wrap: wrap;
    width: 40%;
    height: 80px;
    align-items: center;
    align-content: center;
    display: flex; */
    text-align: center;
    font-size: 24px;
}

.staffing-card h2{
    color: darkblue;
    font-size: 16px;
}

.staffing-card p{
    width: 300px;
    word-spacing: normal;
    /* text-align:justify; */
    justify-content: center;
    text-align: center;
    align-items: center;
    line-height: 25px;
    padding: 20px 10px;
    font-size: 14px;
}
.staffing-card a{
    color: darkgray;
    margin-left: 15%;
    text-decoration: underline;
    text-align: center;
    align-items: center;
    justify-items: center;
    justify-content: center;   
}
.staffing-card .txt{
    line-height: 25px;
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .staffing-topiccontainer1{
        width: auto;
        height: auto;
        margin-left: 0;
        margin-bottom: 130px;
    }
    .staffing-cardcontainer {
        display: flex;
        flex-direction:column;
        /* width: auto; */
        width: 400px;
        margin-left: 0;
    }
    .staffing-card {
        width: auto; 
        max-width: none;
    }
    .staffing-card p {
        width: 100%; 
    }
}

@media screen and (max-width: 480px) {
    .staffing-topiccontainer1{
        width: auto;
        max-width: none;
        margin-bottom: 0px;
    }
    /* .discover{
        font-size: 18px;
        margin-bottom: 15px;
    } */
    .staffing-foryour h1{
        text-decoration: none;
    }
    .staffing-cardcontainer {
        flex-direction:column;
        width: auto;
        max-width: none;
    }
    .staffing-card {
        width:auto;
        max-width: none;
        margin-bottom: 20px;
    }
}
