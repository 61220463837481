.adddigitrans{
    width: 100%;
    height: 400px;
    margin-top: 60px;
    /* margin-left: 50px; */
    background-color: rgb(2, 2, 45);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;
}

.adddigitrans button{
    font-size: 24px;
    padding: 20px 40px;
    border-radius: 20px;
    background-color: darkblue;
    color: white;
    cursor: pointer;
}
@media screen and (max-width:1281px) and (min-width:360px) {
    .adddigitrans{
        position: relative;
        width: auto;
        /* max-width: 100%; */
        max-height: 100%;
    }
    
}
