.digiheader{
    width: 100%;
    height: 100%;
}
.digiheader img{
    width: 100%;
    height: 400px;
    max-height: 100%;
    margin-left: -5px;
    
}

.digiheader h1{
    margin-top: -250px;
    margin-left: 100px;
    font-size: 24px;
    padding: 10px;
    color: white;
}

.digiheader p{
    margin-left: 100px;
    width: 800px;
    font-size: 20px;
    padding: 10px;
    color: white;
}

@media screen and (max-width: 768px) {
    
    .digiheader {
        width: auto;
        height: auto;
    }
    .digiheader img{
        max-width: 100%;
        height: auto;
    }
    .digiheader h1{
        display: flex;
        font-size: 16px;
        margin-top: -200px;
        margin-left: 10px;
    }
    .digiheader p{
        font-size: 14px;
        margin-left: 10px;
        width: auto;
        padding: 10px;
    }
    } 
    
    @media screen and (max-width: 480px) {
        
        .digiheader {
            width: auto;
            height: auto;
        }
        .digiheader img{
            max-width: 100%;
            height: auto;
        }
        .digiheader h1{
            display: flex;
            font-size: 12px;
            margin-top: -120px;
            margin-left: 10px;
        }
        .digiheader p{
            font-size: 10px;
            margin-left: 10px;
            width: auto;
            padding: 10px;
        }
    } 
