.mapabouttech{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    margin-left: 100px;
    margin-top: 50px;
   
}
.mapimage img{
    width: 100%;
    max-width: 600px;
    height: 300px;
    margin-bottom: 50px;
}
.techproductrs{
    width: 100%;
    max-width: 600px;
    height: auto;
    line-height: 2;
    font-size: 16px;
    font-weight: 400;
    margin-left: 150px;
}

@media screen and (max-width:768px) {

    .mapabouttech{
        width: auto;
        height: auto;
        margin-top: 100px;
    }

    .mapimage img{
        width: 300px;
        height: 200px;
        margin-left: 200px;
    }

    .techproductrs{
        width: 100%;
        height: auto;
        margin-left: 100px;
    }

}

@media screen and (max-width: 480px) {

    .mapabouttech{
        width: auto;
        height: auto;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
       
    }

    .mapimage img{
        width: 300px;
        height: 200px;
        margin-left: -70px;
    }

    .techproductrs{
        width: 100%;
        height: auto;
        /* margin-left: 100px; */
    }

}