    .topiccontainer1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box; /* Ensure padding doesn't affect width */

}
.tinnerborder{
    width: 100%;
    /* height: 100vh; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0;    
}
.discover{
    margin-top: -80px;
    margin-left: 100px;
}
.foryourhome h1{
    /* margin-top: -30px; */
    /* margin-left: 0px; */
    font-size: 16px;
}


/* Styles for card container */
.topiccontainer .cardcontainer {
    /* width: 100%; */
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px; 
}

.card {
    width: 400px; 
    height: 350px;
    border: 1px solid lightgray;
    box-shadow: 10px 10px 5px 0px rgba(242, 237, 242, 1);
    border-radius: 20px;
    padding: 20px;
    text-align: center;
}

.card img {
    width: 100px;
    max-width: 100%;
    height: 100px;
    max-height: 100%;
    object-fit: cover; /* Ensure image covers the entire space */
    margin-bottom: 10px; /* Space below the image */
}



.card h2 {
    color: darkblue;
    font-size: 20px;
    margin-top: 10px;
}

.card p {
    width: 300px;
    max-width: 100%;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 10px; 
}

.card:hover {
    cursor: pointer;
    transform: translateY(-5px); /* Lift card on hover */
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.1); /* Enhance shadow on hover */
}

@media screen and (max-width: 768px) {
    .topiccontainer1{
        width: auto;
        height: auto;
        margin-left: 0;
        margin-bottom: 130px;
    }
    .cardcontainer {
        display: flex;
        flex-direction:column;
        width: auto;
        margin-left: 0;
    }
    .card {
        width: auto; 
        max-width: none;
    }
    .card p {
        width: 100%; 
    }
}

@media screen and (max-width: 480px) {
    .topiccontainer1{
        width: auto;
        max-width: none;
        margin-bottom: 0px;
    }
    .discover{
        /* display: none; */
        font-size: 18px;
        margin-bottom: 15px;
    }
    .foryour h1{
        text-decoration: none;
    }
    .cardcontainer {
        flex-direction:column;
        width: auto;
        max-width: none;
    }
    .card {
        width:auto;
        max-width: none;
        margin-bottom: 20px;
    }
}
