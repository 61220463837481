.aboutstaffcontainer{
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.aboutstaff {
    margin-top: 30px;
    width: 500px;
    height: auto;
    margin-left: 40px;
}

.aboutstaff h1{
    font-size: 20px;
}

.aboutstaff p{
    width: 500px;
    text-align: justify;
    font-size: 16px;
    line-height: 2rem;
    
}

.aboutcyberpic img{
   margin-left: 70px;
   margin-top: 150px;
   width: 500px;
   height: auto; 
}

@media screen and (max-width:768px) {

    .aboutstaffcontainer{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        padding: 30px;
        margin-bottom: 120px;
    }
    /* .aboutstaff{
        width: 100%;
        height: auto;
    } */
    .aboutstaff h1{
        font-size: 18px;
        margin-left: -50px;
    }
    .aboutstaff p{
        width: 600px;
        text-align: justify;
        font-size: 16px;
        line-height: 2rem;
        margin-left: -50px;
    }
    .aboutcyberpic img{
        margin-left: 50px;
        width: 600px;
        height: auto; 
     }
}

@media screen and (max-width:480px) {

    .aboutstaffcontainer{
        width: auto;
        height: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .aboutstaff{
        width: 100%;
        height: auto;
    }
    .aboutstaff h1{
        font-size: 12px;
        margin-left: -20px;

    }
    .aboutstaff p{
        font-size: 10px;
        width: 100%;  
        height: auto;
        margin-left: -20px;
    }
    .aboutcyberpic img{
        margin-top: 0;
        margin-left: 0px;
        width: 400px;
        height: auto; 
     }

}