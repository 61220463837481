.entheader{
    width: 100%;
    height: 100%;
}
.entheader img{
    width: 100%;
    height: 400px;
    margin-left: -5px;
    
}

.managednetwork .entheader h1{
    margin-top: -220px;
    margin-left: 100px;
    font-size: 20px;
    padding: 10px;
    color: white;
}

.managednetwork .entheader p{
    margin-left: 100px;
    width: 800px;
    font-size: 18px;
    padding: 10px;
    color: white;
    text-align: justify;
}

@media screen and (max-width:768px) {

    .entheader{
        width: auto;
        height: auto;
    }
    .entheader img{
        width: 100%;
        height: auto;        
    }
    
    .managednetwork .entheader h1{
        margin-top: -260px;
        font-size: 20px;
        padding: 10px;
        margin-left: 40px;
    }
    
    .managednetwork .entheader p{
        width: 600px;
        margin-top: -10px;
        font-size: 16px;
        padding: 10px;
        margin-left: 60px;
    }
}

@media screen and (max-width:480px) {

    .entheader{
        width: auto;
        height: auto;
    }
    .entheader img{
        width: 100%;
        height: auto;        
    }
    
    .managednetwork .entheader h1{
        margin-top: -100px;
        font-size: 16px;
        margin-left: 10px;
    }
    
    .managednetwork .entheader p{
        /* margin-top: -20px;
        width: 280px;
        font-size: 12px;
        padding: 10px;
        margin-left: 10px; */
        display: none;
    }

}