.whatuscardcontainer{
    height: 100%;
    width: 100%;
    margin-top: 30px;
    display: flex;
    margin-left: 0px;
    
}

.whatuscardcontainer .whatuscard{
    height: auto;
    width: 350px;
    border: 1px solid lightgray;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    -moz-box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    box-shadow: 10px 10px 5px 0px rgba(242,237,242,1);
    margin: 20px;
    border-radius: 20px;
    padding: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
}
.whatuscardcontainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.whatusinnerborder{
    left: 0;
    border: 800px;
    margin-left: 0px;
}
.victorimg{
    display: flex;
}

.whatuscontainer .whatuscard img{
    width: 100px;
    height: 100px; 
    border-radius: 50%;
    border: 2px solid rgb(48, 54, 206);
    box-shadow: none  ;
}
.imgmission{
    justify-content: center;
    width: 150px;
    height: 150px;
}

text{
   margin-top: -70px;  
   height: 20px;
   width: 300px;
   display: flex;
   margin-left: 100px;
   font-size: 18px;
   font-weight: 300;
   
}
.whatusforyour{
    /* margin-left: 100px;
    margin-top: -30px;
    text-wrap: wrap;
    width: 40%;
    height: 40px;
    align-items: center;
    align-content: center;
    display: flex; */
    text-align: center;
}
.whatusforyour h1{
    font-size: 24px;
}

.whatuscard h2{
    color: darkblue;
    font-size: 16px;
}
.whatuscard p{
    width: 300px;
    word-spacing: normal;
    text-align:center;
    align-items: center;
    padding: 20px 10px;
    line-height: 25px;
    font-size: 14px;
}

