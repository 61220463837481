 .certi-container {
    width: 100%;
    max-width: 100%;
    height: 300px;
    justify-content: center;
    align-content: center;
    text-align: center;
    background-position: center;
  }
  
  .certcap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 80px;
  }
  
  .certcap p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 30px;
  }
  
  .certification {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .certification img {
    margin: 1px 30px;
    width: 120px; /* Adjust image width as needed */
    max-width: 100%;
    height: 100%; /* Maintain aspect ratio */
    border-radius: 10px; /* Optional: Add rounded corners */
  }
  
  @media screen and (max-width: 768px) {
    .certi-container {
      width: auto;
    }
    .certification img {
      width: 50px;
    }
    .cartcap p{
      font-size: 1rem;
    }
  }

/* Media query for screens up to 480px (phones and smaller screens) */
@media screen and (max-width: 480px) {

.certi-container {
  width: auto;
  margin-top: -120px;
  margin-bottom: -80px;

  }
.certification img {
  width: 35px;
  margin: 10px;
  }
  .certi-container .cartcap p{
    font-size: 10px;
  }

}
   

  
