.mc-headercloud{
    width: 100%;
    height: 100%;
    margin-top: 10px;
}
.mc-headercloud .mcheaderimg{
    width: 100%;
    height: 400px;
    
}

.mc-headercloud h1{
    margin-top: -220px;
    margin-left: 100px;
    font-size: 24px;
    padding: 10px;
    color: white;
}

.mc-headercloud p{
    margin-left: 100px;
    width: 600px;
    font-size: 20px;
    padding: 10px;
    color: white;
    text-align: justify;
}
@media screen and(max-width:768px) {
    .mc-headercloud{
        width: auto;
        height: auto;
    }
    .mc-headercloud .mcheaderimg{
        width: 100%;
        height: auto;        
    }
    .mc-headercloud h1{
        margin-left: 0;
        margin-top: -200px;
        font-size: 16px;
    }
    .mc-headercloud p{
        margin-left: 0;
        width: 100px;
        font-size: 14px;
    }
    

}

@media screen and (max-width:480px) {
    .mc-headercloud{
        width: auto;
        height: auto;
    }
    .mc-headercloud .mcheaderimg{
        width: 100%;
        height: auto;        
    }
    .mc-headercloud h1{
        margin-left: 0;
        margin-top: -120px;
        font-size: 12px;
    }
    .mc-headercloud p{
        margin-left: 10px;
        width: 300px;
        font-size: 10px;
    }
    

}

