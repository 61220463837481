.about-staffcontainer{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-top: 20px; */
    position: relative;
}

.about-staff {
    position: relative;
    margin-top: 30px;
    width: 500px;
    height: auto;
    margin-left: 50px;

}

.about-staff h1{
    font-size: 20px;
}

.about-staff p{
    text-align: justify;
    font-size: 16px;
    line-height: 2rem;
    width: 500px;
    
}

.about-staffingpic img{
   margin-left: 150px;
   margin-top: 100px;
   width: 500px;
   height: auto; 
}
@media screen and (max-width:768px) {

    .about-staffcontainer{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        padding: 30px;
        margin-bottom: 120px;
    }
    /* .about-staff{
        width: 100%;
        height: auto;
    } */
    .about-staff h1{
        font-size: 14px;
    }
    .about-staff p{
        width: 600px;
        text-align: justify;
        font-size: 16px;
        line-height: 2rem;
        margin-left: -70px;
    }
    .about-staffingpic img{
        margin-left: 70px;
        width: 600px;
        height: auto; 
     }
}

@media screen and (max-width:480px) {

    .about-staffcontainer{
        width: auto;
        height: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
    .about-staff{
        width: 100%;
        height: auto;
    }
    .about-staff h1{
        font-size: 12px;
        margin-left: -20px;

    }
    .about-staff p{
        font-size: 10px;
        width: 100%;  
        height: auto;
        margin-left: -20px;
    }
    .about-staffingpic img{
        margin-left: 0px;
        width: 400px;
        height: auto; 
     }

}