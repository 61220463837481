.addcontainer{
    display: flex;
    width: 100%;
    height: 100%;
    /* max-width: 1250%; */
    justify-content: center;
    align-items: center;
}

.addressandform{
    margin-top: 100px;
    width: 100%;
}

.addform{
    margin-bottom: 250px;
}

.timings{
    margin-left: -400px;
    width: 400px;
}
h1{
    font-size: 20px;
}
p{
    font-size: 16px;
}
.form-contactinfo{
    display: flex;
    flex-direction: column;
    margin-left: -400px;
    font-size: 16px;
    width: 400px;

}

.form-socialcontact{
    width: 300px;
    margin-left: -400px;
}

.form-socialcontact .icon{
    height: 40px;
    width: 40px;
    margin: 10px;
}
.form-socialcontact .icon{
    margin-left: 0px;
    color: black;
}


.addressandform p{
    font-size: 16px;
}
.form{
    width: 400px;
    height: 700px;
    margin-left: 250px;
    margin-top: -580px;
    text-align: justify;
}
h2{
    font-size: 18px;
}
.titles{
    color: black;
}

.tomake{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.required{
    color: red;
}
.addform{
    width: 300px;
    height: 600px;
}
.form .tomakeh2{
    width: 600px;
    padding: 0 50px;
    margin-top: 100px;
}
input {
    width: 300px;
    height: 30px;
}
textarea{
    width: 400px;
    height: 100px;
}
.phonelab{
    width: 250px;
    height: 30px;  
}
.formrequest{
    width: 500px;
    height: 620px;
    background-color: white;
    border: 20px solid rgb(191, 244, 244);
    padding: 20px;
    font-size: 16px;
}

.donot{
    width: 400px;
}

.btnsubmit{
    text-align: center;
}
.submitbtn{
    background-color: darkblue;
    color: white;
    padding: 10px 20px;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    font-size: 18px;
    text-align: center;
}
.submitbtn:hover{
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .addcontainer{
        width: auto;
        height: 1800px;
        display: flex;
        flex-direction: column;
        margin-top: -500px;
    }

    .addressandform{
        margin-top: 100px;
        width: 100%;
    }
    .timings{
        margin-left: 0px;
        width: 400px;
    }
    .form-contactinfo{
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        font-size: 16px;
        width: 400px;
    }
    .form-socialcontact{
        width: 300px;
        margin-left: 0px;
    }
    .form{
        width: 400px;
        height: 700px;
        margin-left: 0px;
        margin-top: 0px;
        text-align: justify;
    }

}

@media screen and (max-width: 480px) {

    .addcontainer{
        width: auto;
        height: 1800px;
        display: flex;
        flex-direction: column;
        margin-top: -500px;
    }

    .addressandform{
        margin-top: 100px;
        width: 100%;
    }
    .timings{
        margin-left: 0px;
        width: 400px;
    }
    .form-contactinfo{
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        font-size: 16px;
        width: 400px;
    }
    .form-socialcontact{
        width: 300px;
        margin-left: 0px;
    }
    .form{
        width: 400px;
        height: 700px;
        /* margin-left: 0px; */
        margin-top: 0px;
        text-align: justify;
    }
    .form .tomakeh2{
        width: 400px;
        font-size: 14px;
        margin-left:-70px;
    }
    .formrequest{
        display: flex;
        flex-direction: column;
        width: 400px;
        margin-left: -75px;
    }
    textarea{
        width: 300px;
    }

    .donot{
        width: 300px;
    }
}