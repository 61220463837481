.aboutuscontainer{
    width: 100%;
    height: auto;
    display: flex;
    /* margin-top: 20px; */
    justify-content: center;
    align-items: center;
}

.aboutus {
    margin-top: 30px;
    width: 100%;
    max-width: 600px;
    height: auto;
    margin-left: 40px;
}

.aboutus h1{
    font-size: 20px;
}
.aboutus h2{
    font-size: 18px;
}

.aboutus p{
    text-align: justify;
    font-size: 16px;
    line-height: 2rem;
    width: 100%;
    max-width: 550px;
    
}

.aboutuspic img{
   margin-left: 150px;
   margin-top: 100px;
   width: 400px;
   height: auto; 
}

@media screen and (max-width: 768px) {
    .aboutuscontainer {
        width: auto;
        height: auto;
        margin-top: 50px;
        margin-bottom: 130px;

    }

    .aboutus{
        width: 300px;
        margin-left: 10px;

        }
    .aboutus h1{
        font-size: 14px;
    }

    .aboutus p{
        font-size: 12px;
    }

    .aboutuspic img{
        width: 250px;
        height: auto;
    }
}

@media screen and (max-width: 480px) {
    .aboutuscontainer {
        width: auto;
        height: auto;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;

    }

    .aboutus{
        width: 100%;
        height: auto;
        padding: 20px;

        }
    .aboutus h1{
        font-size: 12px;
    }

    .aboutus p{
        font-size: 10px;
    }

    .aboutuspic img{
        margin-left: 0;
        width: 300px;
        height: auto;
        margin-top: -20px;
        margin-bottom: 20px;
    }
}
