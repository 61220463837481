.mcheader{
    width: 100%;
    height: 100%;
    margin-top: 10px;
}
.mcheader img{
    width: 100%;
    height: 400px;
    margin-left: -5px;
    
}

.mcheader h1{
    margin-top: -220px;
    margin-left: 100px;
    font-size: 24px;
    padding: 10px;
    color: white;
}

.mcheader p{
    margin-left: 100px;
    width: 800px;
    font-size: 20px;
    padding: 10px;
    color: white;
    text-align: justify;
}

@media screen and (max-width:768px) {

    .mcheader{
        width: auto;
        height: auto;
    }
    .mcheader img{
        width: 100%;
        height: auto;        
    }
    
    .mcheader h1{
        margin-top: -250px;
        font-size: 20px;
        padding: 10px;
        margin-left: 10px;
    }
    
    .mcheader p{
        width: 400px;
        font-size: 16px;
        padding: 10px;
        margin-left: 10px;
    }
}

@media screen and (max-width:480px) {

    .mcheader{
        width: auto;
        height: auto;
    }
    .mcheader img{
        width: 100%;
        height: auto;        
    }
    
    .mcheader h1{
        margin-top: -150px;
        font-size: 16px;
        margin-left: 10px;
    }
    
    .mcheader p{
        margin-top: -20px;
        width: 280px;
        font-size: 12px;
        padding: 10px;
        margin-left: 10px;
    }

}
