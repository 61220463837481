.cs1-container{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cs1-container .about-cs {
    margin-top: 30px;
    width: 500px;
    height: auto;
    margin-left: 40px;
}

.about-cs h1{
    font-size: 20px;
}

.cs1-container .about-cs p{
    width: 500px;
    text-align: justify;
    font-size: 16px;
    line-height: 2rem;
    
}

.cs1-container .about-cspic img{
   margin-left: 100px;
   margin-top: 100px;
   width: 500px;
   height: auto; 

}

@media screen and (max-width: 768px) {
    .cs1-container{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
    }
    
    .about-cs h1{
        font-size: 18px;
        margin-left: -50px;
    }
    
    .cs1-container .about-cs p{
        width: 600px;
        text-align: justify;
        font-size: 16px;
        line-height: 2rem;
        margin-left: -50px;
    }
    
    .cs1-container .about-cspic img{
       margin-top: -10px;
       width: 600px;
       height: auto; 
       margin-left: 50px;
    }
}

@media screen and (max-width: 480px) {
    .cs1-container{
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
    }
    .cs1-container .about-cs{
        margin-top: 20px;
    }
    
    .cs1-container .about-cs h1{
        font-size: 14px;
        margin-left: 50px;
    }
    
    .cs1-container .about-cs p{
        width: 350px;
        text-align: justify;
        font-size: 12px;
        line-height: 2rem;
        margin-left: 50px;
    }
    
    .cs1-container .about-cspic img{
       margin-top: -10px;
       width: 300px;
       height: auto; 
       margin-left: 50px;
    }
}