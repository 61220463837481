.aboutheader{
    width: 100%;
    height: 100%;
}
.aboutheader img{
    width: 100%;
    height: 100%;
    max-height: 400px ;
    
}

.aboutheader h1{
    margin-top: -170px;
    margin-left: 100px;
    font-size: 24px;
    color: white;
}

.aboutheader p{
    /* margin-top: -170px; */
    margin-left: 100px;
    width: 600px;
    font-size: 18px;
    color: white;
}

@media screen and (max-width: 768px) {
    
    .aboutheader {
        width: auto;
        height: auto;
    }
    .aboutheader img{
        max-width: 100%;
        height: auto;
    }
    .aboutheader h1{
        display: flex;
        font-size: 16px;
        margin-top: -200px;
        margin-left: 350px;
    }
    .aboutheader p{
        font-size: 14px;
        margin-left: 350px;
        width: auto;
        padding: 10px;
    }
    } 
    
    @media screen and (max-width: 480px) {
        
        .aboutheader {
            width: auto;
            height: auto;
        }
        .aboutheader img{
            max-width: 100%;
            height: auto;
            margin-top: -20px;
        }
        .aboutheader h1{
            display: flex;
            font-size: 12px;
            margin-top: -120px;
            margin-left: 150px;
        }
        .aboutheader p{
            font-size: 10px;
            margin-left: 150px;
            width: auto;
            padding: 10px;
        }
    } 

    
