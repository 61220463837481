.staf-header{
    width: 100%;
    height: 100%;
}
.staf-header img{
    width: 100%;
    height: 400px;
    
}

.staf-header h1{
    margin-top: -300px;
    margin-left: 100px;
    font-size: 24px;
    padding: 10px;
    color: white;
}

.staf-header p{
    margin-left: 100px;
    width: 800px;
    font-size: 20px;
    padding: 10px;
    color: white;
    text-align: justify;
}
@media screen and (max-width:768px) {

    .staf-header{
        width: auto;
        height: auto;
    }
    .staf-header img{
        width: 100%;
        height: auto;
    }
    .staf-header h1{
        margin-top: -200px;
        margin-left: 10px;
        font-size: 16px;
    }
    .staf-header p{
        margin-left: 10px;
        width: auto;
        font-size: 14px;
    }
    
}

@media screen and (max-width:480px) {

    .staf-header{
        width: auto;
        height: auto;
    }
    .staf-header img{
        width: 100%;
        height: auto;
    }
    .staf-header h1{
        margin-top: -130px;
        margin-left: 10px;
        font-size: 12px;
    }
    .staf-header p{
        margin-left: 10px;
        margin-top: -20px;
        width: auto;
        font-size: 10px;

    }
    
}
