.navbar {
  height: 15vh;
  max-height: 100%;
  width: 100%;
    background-color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.125rem;
    position: relative;
    z-index: 1000;

  }  
  
  .navlogo {
    width: 12rem;
    height: 5rem;
    margin-left: 3rem;
  }
  
  .navbar-nav {
    position: relative;
    list-style-type: none;
    display: flex;
    align-items: center;
  }
  
  .nav-item {
    position: relative;
    margin-right: 0.625rem;

  }
  .nav-item a{
    text-decoration: none;
    margin-right: 3rem;
    color: darkblue;
    font-weight: 600;

   }
  
  .dropdown-btn {
    background-color: transparent;
    color: darkblue;
    border: none;
    cursor: pointer;
    padding: 10px 15px;
    font-size: 1.125rem;
    font-weight: 600;
    margin-right: 4rem;
    margin-left: -1.25rem;

  }
  
  .dropdown-menu {
    position: absolute;
    background-color: white;
    border-radius: 10px;
    padding: 5px 0 15px 0px;
    list-style-type: none;
    display: none;
    width: 16.25rem;
    text-align:left;
    font-size: 1rem;
    font-weight: 500;
  }
  
  .dropdown-menu li {
    margin: 0px;
    padding: 5px 0 5px 0px;
  }

  .dropdown-menu li:hover{
    transform: scale(1.05);
  }
  
  .dropdown-menu a {
    text-decoration: none;
    padding: 5px 0 5px 20px;
    display: block;
    font-weight: 500;

  }
  
  .dropdown-menu a:hover {
    transform: scale(1.01);
  }
  
  .submenu-btn {
    background-color: transparent;
    border: none;
    color: darkblue;
    cursor: pointer;
    font-size: 1.125rem;
    padding: 5px 10px;
    right: 100%;
    top: 0;
  }
  .dropdown-menu .submenu1{
    font-family: sans-serif ;
    display: flex;
    color: darkblue;
    padding: 0px 0 0.313rem 0.625rem;
    font-size: 1.125rem;

  }
  
  .submenu-items {
    background-color: white;
    border-radius: 10px;
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 100%;
    margin-top: -30px;
    width: 380px;
    font-weight: 500;
    white-space:initial;
  }
  
  .submenu-items li{
    margin: 10px;
    font-weight: 500;
  }

  .submenu-items li a{
    font-weight: 500;
  }
  
  .submenu-items a {
    text-decoration: none;
    padding: 5px 5px;
    display: block;
  }
  
  .submenu-items a:hover {
    transform: scale(1.01);
  }
  
  .dropdown:hover .dropdown-menu {
    display: block;
  }
  
  .submenu:hover .submenu-items {
    display: block;
  }

  .subtext h1{
    font-style: normal;
    text-decoration: none;
    color: white;
  }
  .btn-contact{
    padding: 10px 10px;
    background-color: darkblue;
    font-size: 1.125rem;
    border-radius: 10px;
    margin-right: 5rem;
    cursor: pointer;
  }  
  .btn-contact a{
    text-decoration: none;
    color: white;

  }

  @media (max-width: 768px) {
    .navbar {
      height: auto;
      flex-direction: column;
      padding: 15px;
      font-size: 12px;
      z-index: 1000;
    }

    .navbar-nav {
      margin-top: 10px;
    }
  
    .nav-item,
    .btn-contact {
      margin: 10px 0;
      font-size: 12px;
      white-space: nowrap;
      margin-left: -10px;
    }
    .btn-contact{
      margin-left: -40px;
    }  
    .dropdown-menu {
      display: block;
      margin-top: 0;
      width: fit-content;
    }
    .dropdown-btn{
      font-size: 12px;
    }
  
    .submenu-btn {
      width: 100%;
      text-align: left;
      font-size: 12px;
    }
    .dropdown-menu .submenu1{
      font-size: 12px;
    }
    .submenu-items{
      width: 100%;
      max-width: 200px;
    }
    .submenu-items li{
      width: 100%;
      font-size: 10px;
      display: flex;
      flex-direction: column;
      text-align: right;
      word-wrap: normal;
    }
  }

 