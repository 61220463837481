.partnersimagecontainer1{
    margin-top: 30px;
    width: 100%;
    height: 300px;
    justify-content: center;
    align-content: center;
    background-position: center;
    border-radius: 20px;  
}

.partnersheadding h1{
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.5rem;
}

.partnersimage1 img{
    width: 200px;
    height: 100px;
    border-radius: 10px;  
    padding: 2px;

}

@media screen and (max-width: 768px) {
    .partnersimagecontainer1 {
      width: auto;
    }
    .partnersimage1 img {
      width: 150px;
      height: auto;
    }
  }

/* Media query for screens up to 480px (phones and smaller screens) */
@media screen and (max-width: 480px) {

  .partnersimagecontainer1 {
    width: auto;
    margin-top: -20px;
    margin-bottom: -80px;

  }
.partnersimage1 img {
  width: 100px;
  height: auto;
  }

}